import React from 'react'
import Heading from '../../components/typograph/Heading'
import Layout from '../../components/layout/Layout'
import PageContainer from '../../components/layout/PageContainer'
import Hero from '../../components/hero/Hero'
import styled from 'styled-components'
import { DARK_GREEN, ORANGE, TEXT } from '../../constants/Colors'

import coursesImage from '../../images/Banner-Cursos-Desk.jpg'
import coursesImageMobile from '../../images/Banner-Cursos-Mobile.jpg'
import LinkButton from '../../components/buttons/LinkButton'
import GatsbyLink from 'gatsby-link'
import { CommonMeta } from '.'

const Container = styled.div`
  max-width: 1000px;
  padding: 10px;
  margin: 0 auto;
  text-align: center;
  color: ${TEXT};
  line-height: 1.5;
`

const MatriculaEnviada = () => (
  <Layout
    title="Matricula - HDScience"
    meta={CommonMeta(
      'cursos-e-minicursos/matricula-enviada',
      'Matricula - HDScience'
    )}
  >
    <Hero image={coursesImage} mobileImage={coursesImageMobile}>
      <Heading
        type="h1"
        style={{ color: '#fff', textAlign: 'center', paddingTop: 30 }}
      >
        CURSOS &<br />
        MINI-CURSOS
      </Heading>
    </Hero>
    <PageContainer style={{ marginBottom: 40 }}>
      <Heading
        type="h2"
        style={{ marginBottom: 16, textAlign: 'center' }}
        color={DARK_GREEN}
      >
        Agora Falta Muito Pouco!
      </Heading>
      <Container>
        Obrigado por se matricular em nosso curso!
        <br />
        <br />
        Em breve entraremos em contato para confirmar sua
        <br />
        matrícula, passar as informações sobre o início do curso
        <br />e link para proceder com pagamento pelo PagSeguro.
        <br />
        <br />
        Em caso de dúvidas, entre em contato por telefone,
        <br />
        e-mail ou utilizando nosso{' '}
        <GatsbyLink
          to="/contato/"
          style={{ textDecoration: 'none', color: ORANGE, fontWeight: 'bold' }}
        >
          formulário de contato
        </GatsbyLink>
        !<br />
        <br />
        <br />
        <br />
        <LinkButton to="/cursos-e-minicursos/">
          VOLTAR PARA OS CURSOS
        </LinkButton>
      </Container>
    </PageContainer>
  </Layout>
)

export default MatriculaEnviada
